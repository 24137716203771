.full-page-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .9;
}

.bg-white {
    background: white;
}

.flex-ass {
    align-self: flex-start;
}
.header-bar > div {
    justify-content: flex-end !important;
}

.ft-md .type-image,
.md-md .type-image {
    height: 85px;
    min-width: 85px;
    max-width: 85px;
    overflow: hidden;
    border-radius: 4px;
}

.ft-md .type-image img,
.md-md .type-image img {
    width: 100%;
    object-fit: contain;
}

.ft-md .ft-md-container .ft-md-content {
    max-height: unset !important;
    overflow-y: unset !important;
}

.ft-md .input-group.my0 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
  
.inverted-colours .ft-md-close:hover,
.inverted-colours .modal-close:hover,
.inverted-colours .sm-md-close:hover {
    background-color: #2c3a46 !important;
}

.page-header > div { 
    display: block !important;
    width: auto;
}

.md-content > div > div { 
    width: 80% !important;
}

.md-content > div > div > div { 
    justify-content: flex-start !important;
}